<template>
  <v-container fluid>
    <!-- v-select with complete, all, not complete -->
    <v-row>
      <v-col cols="12" md="3">
        <v-select
          :items="['Not Complete', 'Complete', 'All']"
          label="Filter"
          v-model="filter"
        ></v-select>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="rows"
      :loading="loading"
      :items-per-page="10"
      class="elevation-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.requested_count="{ item }">
        {{ parseFloat(item.requested_count).toLocaleString() }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.events="{ item }">
        <span style="white-space: nowrap" v-html="item.events"></span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.created="{ item }">
        <span style="white-space: nowrap" v-html="item.created"></span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.mail_date="{ item }">
        <span style="white-space: nowrap" v-html="item.mail_date"></span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.address="{ item }">
        <span v-html="item.address"></span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.osi_mail_date="{ item }">
        <v-dialog v-model="dynamicModel[item.id]" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.osi_mail_date"
              label="Mail Date"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="saveDate(item.id, null)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="item.osi_mail_date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dynamicModel[item.id] = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDate(item.id, item.osi_mail_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.accudata_order_id="{ item }">
        <v-icon v-if="item.accudata_order_id" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>

      <template v-slot:item.osi_transfer_id="{ item }">
        <a
          :href="
            'https://dashboard.stripe.com/payments/' + item.osi_transfer_id
          "
          target="_blank"
        >
          Open
        </a>
      </template>

      <template v-slot:item.pdf="{ item }">
        <a
          :href="'https://templates.annuitycheck.com/seminar_helper/' + item.id"
          target="_blank"
        >
          Open
        </a>
      </template>
    </v-data-table>
  </v-container>
</template>

<style scoped>
/deep/ .v-text-field {
  width: 120px;
}
</style>

<script>
import gql from "graphql-tag";
import dayjs from "dayjs";

export default {
  name: "Home",

  methods: {
    saveDate(id, osi_mail_date) {
      // update the date in the database using hasura

      this.$apollo.mutate({
        mutation: gql`
          mutation updateOSIDate($osi_mail_date: date, $id: Int!) {
            update_campaigns_by_pk(
              pk_columns: { id: $id }
              _set: { osi_mail_date: $osi_mail_date }
            ) {
              id
            }
          }
        `,
        variables: {
          id: id,
          osi_mail_date: osi_mail_date,
        },
      });

      this.dynamicModel[id] = false;
    },
  },

  async mounted() {
    const { data } = await this.$apollo.query({
      query: gql`
        query ManageACcom {
          campaigns(where: { status: { _neq: "created" } }) {
            accudata_order_id
            accudata_request_id
            automation
            book_status
            book_tracking_num
            events {
              id
              start_date
            }
            city
            created
            id
            mail_date
            osi_transfer_id
            osi_ppm
            requested_count
            state
            status
            street
            user {
              email
              last_name
              first_name
              id
            }
            venue_name
            zip
            mail_type
            osi_mail_date
          }
        }
      `,
    });

    this.raw_rows = data.campaigns.map((r) => {
      return {
        ...r,
        mail_date: r.mail_date
          ? new Date(r.mail_date).toISOString().slice(0, 10)
          : "Sending: " +
            dayjs(r.events[0].start_date)
              .subtract(21, "days")
              .format("YYYY-MM-DD"),
        address: `${r.street}<br>${r.city}, ${r.state} ${r.zip}`,
        name: r.user.first_name + " " + r.user.last_name,
        created: new Date(r.created).toISOString().slice(0, 10),
        events: r.events
          .map((e) => {
            return new Date(e.start_date).toISOString().slice(0, 10);
          })
          .join("<br>"),
      };
    });
    this.loading = false;
  },

  computed: {
    rows() {
      if (this.filter == "Not Complete") {
        return this.raw_rows.filter((r) => {
          return r.status !== "complete";
        });
      } else if (this.filter == "Complete") {
        return this.raw_rows.filter((r) => {
          return r.status == "complete";
        });
      } else {
        return this.raw_rows;
      }
    },
  },

  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Agent", value: "name" },
        { text: "Venue", value: "venue_name" },
        { text: "Events", value: "events" },
        { text: "Address", value: "address" },
        { text: "Count", value: "requested_count" },
        { text: "Status", value: "status" },
        { text: "Created", value: "created" },
        { text: "Mail Type", value: "mail_type" },
        { text: "OSI Transfer Date", value: "mail_date" },
        { text: "OSI PPM", value: "osi_ppm" },
        { text: "Mail Date", value: "osi_mail_date" },
        { text: "AccuData", value: "accudata_order_id", sortable: false },
        { text: "OSI Transfer", value: "osi_transfer_id", sortable: false },
        { text: "PDF", value: "pdf", sortable: false },
      ],
      loading: true,
      sortBy: "events",
      sortDesc: true,
      filter: "Not Complete",
      modal: false,
      dynamicModel: {},
      date: undefined, //
      // new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),

      raw_rows: [],
    };
  },
};
</script>
