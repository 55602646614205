import ApolloClient from "apollo-boost";
import VueApollo from "vue-apollo";
import Vue from "vue";

Vue.use(VueApollo);

export const apolloClient = new ApolloClient({
  uri: "https://graphql.annuitycheck.com/v1/graphql",
  headers: {
    "content-type": "application/json",
    "x-hasura-admin-secret":
      "AdF2GD0Q6iBjOpO0SGohGPi6xSHq44dGHguz2n7Kn6Q1QlqqO78io9B2tSO18E13",
  },
});

export default new VueApollo({
  defaultClient: apolloClient,
});
