<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <span class="mr-2">AnnuityCheck</span>
      </div>

      <v-spacer></v-spacer>

      <v-tabs v-model="activeTab" right>
        <v-tab key="tab-1" to="/" ripple> Campaigns </v-tab>
        <v-tab key="tab-2" to="/book" ripple> Books </v-tab>
        <v-tab key="tab-3" to="/analytics" ripple> Analytics </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
.v-data-table .v-data-table-header tr th {
  white-space: nowrap !important;
}
</style>

<script>
export default {
  name: "App",

  data: () => ({
    activeTab: 0,
  }),
};
</script>
